$rem: 16px;

// Colors
$color-foreground: #E0E0E0;
$color-foreground-alt: #97ACB9;
$color-foreground-top-bar: #E1E4E6;

$color-background: #101010;
$color-background-dark: #282828;
$color-background-light: #444444;
$color-background-modal: #00000099;

$color-success: hsl(84, 45%, 55%);
$color-danger: hsl(355, 73%, 71%);

$color-accent: #0066ff;

// Dark theme?
// $color-foreground: #FFFFFF;
// $color-foreground-alt: #97ACB9;

// $color-background: #323233;
// $color-background-dark: #0D161A;
// $color-background-light: #323233;
// $color-background-modal: #00000088;

// Fonts
$font-size: $rem;
$font-family: "IBM Plex Sans", sans-serif;

// List vars and breakpoints
$list-min-width: 4in;
$list-max-width: 5in;

$break-width: $list-min-width * 2;
$break-width: calc((2 * #{$list-min-width}) + (8 * #{$rem}));

// Assorted sizes
$height-top-bar: 3rem;
$modal-max-width: 10in;
