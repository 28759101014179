@import './constants';

// Global styles
html {
    font-size: $font-size;
}

body {
    margin: 0;
    padding: 0;

    font-family: $font-family;
    color: $color-foreground;
    background-color: $color-background;
}

textarea {
    margin: 0 1rem;
    padding: 1rem;

    font-size: $font-size;
    font-family: $font-family;

    color: $color-foreground;
    background-color: $color-background-light;
}

h1 {
    margin: 0;
    font-size: 1.25rem;
    font-weight: normal;
    line-height: 1.25rem;
}
